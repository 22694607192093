/* PART - SECTION HEADING
--------------------------------------------------------------------------------------------*/
.ac_heading {
  margin: 0 auto;
  position: relative;
  z-index: 2;

  &.v_title-centered_button-right {
    .ac_heading_title {
      text-align: center;
    }
    .ac_heading_link {
      @include breakpoint(medium-portrait down) {
        text-align: center;
      }
      @include breakpoint(medium) {
        position: absolute;
        right: 0;
        top: 0;
      }
    }
  }

  &.v_title-centered_button-centered {
    .ac_heading_link,
    .ac_heading_title {
      text-align: center;
    }
  }

  &.v_title-left_button-right {
    @include breakpoint(medium) {
      .ac_heading_container {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
}

.ac_heading_container {
  padding: 0 $default-padding;
  position: relative;
}

.ac_heading_title {
  margin-bottom: 1em;
}

.ac_heading_link {
  white-space: nowrap;
  margin-bottom: 1em;
}
