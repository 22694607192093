
/* FILTER - 1
--------------------------------------------------------------------------------------------*/

[data-s-type="section-content"] {
    a:not(.button) {
        text-decoration: underline;
    }
   
} // end of scope

[data-s-id="home-content-block-with-background"]{
    p {
        background: #fff;
        padding: 2rem 2.4rem;
        border-radius: .3rem;
    }
    .a_text_brand_white a{
        color: #fff;
        &:hover{
            color: #fff;
        }
    }
}


