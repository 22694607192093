/* SECTION CONTROL
--------------------------------------------------------------------------------------------*/
[data-s-id="home-toptasks"] {
  position: relative;
  z-index: 3;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  margin-top: -6rem;
}

[data-s-id="home-services"] {
  .ac_item_content {
    background: $brand-white;
  }
}
