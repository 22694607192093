/* AC_S_IMAGE-GALLERY_FULL-SCREEN - 1
--------------------------------------------------------------------------------------------*/

$ac-image-gallery-full-screen-z-index: 4444;
$ac-image-gallery-full-screen-background: rgba($brand-secondary-darker, 0.9);

// content
$ac-image-gallery-full-screen-content-background: rgba(
  $brand-secondary-darker,
  0.9
);
$ac-image-gallery-full-screen-content-color: $default-text-color-light;
$ac-image-gallery-full-screen-content-max-width: $max-content-1000;
$ac-image-gallery-full-screen-content-padding: $default-padding;

// close icon
$ac-image-gallery-full-screen-close-icon-size: 40px;
$ac-image-gallery-full-screen-close-icon-border-radius: $default-border-radius /
  2;
$ac-image-gallery-full-screen-close-icon-padding: 12px;
$ac-image-gallery-full-screen-close-icon-background-color: $default-background-light;
$ac-image-gallery-full-screen-close-icon-background-hover-color: $brand-secondary-darker;
$ac-image-gallery-full-screen-close-icon-color: $default-icon-color-dark;
$ac-image-gallery-full-screen-close-icon-hover-color: $default-icon-color-light;

@import "__image-gallery_full-screen_globals";

.image-gallery_full-screen_item_image_container {
  img {
    @include breakpoint(large) {
      max-height: 70vh;
    }
  }
}

[data-s-type="image-gallery_full-screen"] {
  .slick-next,
  .slick-prev {
    svg {
      fill: white;
    }
  }
}
