/* PAGE CONTROL
--------------------------------------------------------------------------------------------*/
body.home {
   
}

.single-product{
    [data-f-type="footer-1"]{
        margin-top: 0;
    }
}