/* AC_S_TOPTASKS - 1
--------------------------------------------------------------------------------------------*/

// items
$ac-toptasks-items-max-width: $max-content-1000;
$ac-toptasks-items-padding: 0;
$ac-toptasks-items-inner-wrapper-enabled: false;
$ac-toptasks-items-inner-wrapper-padding: $default-padding / 4;
$ac-toptasks-items-inner-wrapper-background-color: $brand-lightest-gray;
$ac-toptasks-items-have-border-dividers: false;

// item
$ac-toptasks-item-padding: $default-padding / 2;
$ac-toptasks-item-container-padding: $default-padding;
$ac-toptasks-item-has-shadow: true;
$ac-toptasks-item-background-color: $brand-white;
$ac-toptasks-item-background-hover-color: $brand-white;
$ac-toptasks-item-border-radius: $default-border-radius;

// image
$ac-toptasks-item-has-image-container: false;
$ac-toptasks-item-has-image-container-height: 120px;
$ac-toptasks-item-has-image-container-background-color: transparent;
$ac-toptasks-item-has-image: true;
$ac-toptasks-item-has-image-initial-fade: 0.1;
$ac-toptasks-item-has-image-hover-fade: 0.5;

// icon
$ac-toptasks-item-icon-enbled: true;
$ac-toptasks-item-icon-width: 100px; // px of em
$ac-toptasks-item-icon-padding: 24px; // px or em - is deducted from icon width. Say width is 80px and padding is 15px, the actual icon is 50px;
$ac-toptasks-item-icon-margin-bottom: 20px;
$ac-toptasks-item-icon-bg: $brand-primary-lighter;
$ac-toptasks-item-icon-hover-bg: lighten($brand-primary-lighter, 5%);
$ac-toptasks-item-icon-fill: $brand-primary;
$ac-toptasks-item-icon-hover-fill: $default-icon-color-dark;

// title
$ac-toptasks-item-title-color: $default-text-color;
$ac-toptasks-item-title-color-hover: rgba($default-text-color, 0.6);

// description
$ac-toptasks-item-description-enabled: true;
$ac-toptasks-item-description-color: $default-text-color;
$ac-toptasks-item-description-color-hover: rgba($default-text-color, 0.6);
$ac-toptasks-item-description-margin-bottom: 1em;

// btn-container
$ac-toptasks-item-btn-container-enabled: true;
$ac-toptasks-item-btn-container-color: $default-text-color;
$ac-toptasks-item-btn-container-color-hover: rgba($default-text-color, 0.6);

@import "__toptasks_globals";

/*--------------------------------------------------------------------------------------------*/

.ac_toptasks_item {
  transition: transform 0.3s ease;
  user-select: none;

  @include breakpoint(large) {
    &:hover {
      transform: translateY(-5px);

      .ac_toptasks_item_container {
        border-bottom: solid 2px $brand-secondary;
        box-shadow: 0 0 20px rgba(black, 0.2);
      }
    }
  }
}

.ac_toptasks_item_container {
  display: flex;
  align-items: center;
  box-shadow: 0 0 10px rgba(black, 0.05);
  border-bottom: solid 2px lighten($brand-secondary-lighter, 20%);

  @include breakpoint(medium) {
    display: block;
  }
}

.ac_toptasks_item_icon-container {
  @include breakpoint(medium-portrait down) {
    flex: 0 0 ($ac-toptasks-item-icon-width * 0.66);
    padding-right: $default-padding;
  }

  @include breakpoint(medium) {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -($ac-toptasks-item-icon-width * 0.66);
  }
}

.ac_toptasks_item_icon {
  // box-shadow: $default-box-shadow;

  @include breakpoint(medium-portrait down) {
    width: $ac-toptasks-item-icon-width / 2;
    height: $ac-toptasks-item-icon-width / 2;
    padding: $ac-toptasks-item-icon-padding / 2;

    svg {
      width: $ac-toptasks-item-icon-width / 2 - $ac-toptasks-item-icon-padding;
      height: $ac-toptasks-item-icon-width / 2 - $ac-toptasks-item-icon-padding;
    }
  }
}

.ac_toptasks_item_content {
  @include breakpoint(medium) {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
}
