/* AC_S_IMAGE-GALLERY-SLIDER - 1
--------------------------------------------------------------------------------------------*/

//items
$ac-image-gallery-items-max-width: $max-content-1000;
$ac-image-gallery-items-have-padding: false;
$ac-image-gallery-items-on-small-two-items-a-row: true;

// item
$ac-image-gallery-item-background: $brand-white;
$ac-image-gallery-item-padding: 0 $default-padding / 2;
$ac-image-gallery-item-border-radius: $default-border-radius;
$ac-image-gallery-item-hover-background: rgba($default-background-dark, 0.1);
$ac-image-gallery-item-margin-bottom: 20px; // use same measure unit as column specs
$ac-image-gallery-item-content-padding: $default-padding;
$ac-image-gallery-item-content-align: "cc"; // lt, lc, lb, ct, cc, cb, rt, rc, rb
$ac-image-gallery-item-border: $default-border; // defines the appearance, not the display
$ac-image-gallery-item-has-shadow: false;

// image
$ac-image-gallery-item-image-size: "cover"; // contain or cover. The HTML element cannot have a a_cover_.. class.
$ac-image-gallery-item-image-height: 540px;
$ac-image-gallery-item-image-background: $brand-secondary-darker;
$ac-image-gallery-item-image-initial-opacity: 1;
$ac-image-gallery-item-image-hover-opacity: 0.5;

// description
$ac-image-gallery-item-description-enabled: false;
$ac-image-gallery-item-description-color: $default-text-color-dark;
$ac-image-gallery-item-description-hover-color: $default-text-color-dark;
$ac-image-gallery-item-description-word-break: false;

@import "__image-gallery_globals";

/*--------------------------------------------------------------------------------------------*/

[data-s-type="image-gallery"] {
  &.v_is-slider {
    .ac_image-gallery_container {
      &:before {
        content: "";
        position: absolute;
        z-index: 3;
        left: 0;
        width: 5rem;
        height: 100%;
        background: linear-gradient(
          90deg,
          rgba($brand-white, 1),
          rgba($brand-white, 0)
        );
      }
      &:after {
        content: "";
        position: absolute;
        z-index: 3;
        right: 0;
        width: 5rem;
        height: 100%;
        background: linear-gradient(
          90deg,
          rgba($brand-white, 0),
          rgba($brand-white, 1)
        );
      }
    }
    .ac_image-gallery_item_image_container {
      height: 50vw;
      @include breakpoint(large) {
        height: 540px;
      }
    }
  }
}

.ac_image-gallery_item_image_container {
  height: 30vw;
  @include breakpoint(large) {
    height: 400px;
  }
}
