/* FOOTER 1 - 1
--------------------------------------------------------------------------------------------*/

[data-f-type="footer-1"] {
  // footer
  $footer-background: $brand-background-primary;
  $footer-content-width: $max-content-1000;

  // primary container
  $footer-primary-enabled: true;
  $footer-primary-background: transparent;
  $footer-primary-padding: ($default-padding * 2) ($default-padding / 2);
  $footer-primary-color: $default-text-color-light;
  $footer-primary-link-color: rgba($brand-white, 1);
  $footer-primary-link-hover-color: rgba($brand-white, 0.7);
  $footer-primary-column-1-enabled: true;
  $footer-primary-column-2-enabled: true;
  $footer-primary-column-3-enabled: true;
  $footer-primary-column-4-enabled: false;

  // width of columns above medium. Any combination is possible, as long as it adds up to 100
  $footer-primary-column-width-1: 1 / 3;
  $footer-primary-column-width-2: 1 / 3;
  $footer-primary-column-width-3: 1 / 3;
  $footer-primary-column-width-4: 1 * 0.2;
  $footer-primary-collapsed-hide-column-1: false;
  $footer-primary-collapsed-hide-column-2: false;
  $footer-primary-collapsed-hide-column-3: false;
  $footer-primary-collapsed-hide-column-4: false;
  $footer-primary-collapsed-align-center: true;

  // secondary container
  $footer-secondary-enabled: true;
  $footer-secondary-background: rgba($brand-white, 0.1);
  $footer-secondary-padding: ($default-padding / 2) ($default-padding / 2);
  $footer-secondary-color: rgba($brand-white, 0.5);
  $footer-secondary-link-color: rgba($brand-white, 0.5);
  $footer-secondary-link-hover-color: rgba($brand-white, 0.9);
  $footer-secondary-column-1-enabled: true;
  $footer-secondary-column-2-enabled: true;
  $footer-secondary-column-width-1: 1 * 0.5;
  $footer-secondary-column-width-2: 1 * 0.5;
  $footer-secondary-column-1-justify: flex-start;
  $footer-secondary-column-2-justify: flex-end;
  $footer-secondary-collapsed-align-center: true;

  // socials
  $footer-socials-icon-background: $brand-tertiary-darker;
  $footer-socials-icon-hover-background: $brand-white;
  $footer-socials-icon-color: $brand-white;
  $footer-socials-icon-hover-color: $brand-tertiary-darker;

  @import "../ac_f__footer-globals/__footer_globals";

  /*--------------------------------------------------------------------------------------------*/

  // globals
  @if $footer-background != transparent or $footer-background != none {
    background: $footer-background;
  }

  margin-top: 2rem;
  font-size: 90%;

  @include breakpoint(medium only) {
    padding-bottom: 80px;
  }

  h2 {
    font-size: 130%;
    margin-bottom: $default-padding / 2;
    font-weight: bold;

    // opacity: .5;
  }

  ul {
    list-style: none;
    margin: 0;
  }

  a {
    transition: opacity 0.3s ease;
  }

  //
  //      COLUMN
  //

  // margin fix for news items, if in second column
  .ac_footer_column {
    &:nth-child(2) {
      .ac_footer_link {
        line-height: 1.3;
        margin-bottom: $default-padding;
      }
    }
  }

  //
  //      PRIMARY
  //

  .ac_footer_primary_link {
    display: block;
  }

  .ac_footer_primary_link_copy_above {
    text-transform: uppercase;
    display: block;
    opacity: 0.5;
    font-size: 80%;
  }

  //
  //      SECONDARY
  //
  .ac_footer_secondary {
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 90%;
  }

  .ac_footer_secondary_link {
    display: block;
  }
} // end of scope
