
/* AC_S_PARTNERS - GLOBALS
--------------------------------------------------------------------------------------------*/



//
//      SECTION PARTNERS
//


[data-s-type="partners"] {

    position: relative;
    padding: $default-padding 0;
    
    @include breakpoint(medium) {
        padding: 0 0 $default-padding / 2 0;
    }   

    &.v_is-slider {
        &.v_has-dots {
            .ac_brands_container {
                margin-bottom: 4em;
            }
        }

        .slick-dots {
            bottom: -4em;
        }
    }

}


//
//      CONTAINER
//

.ac_partners_container{
    // max-width: $max-content-1200;
    justify-content: center;
    position: relative;
    z-index: 2;
    margin: 2em auto;
    display: flex;
    flex-wrap: wrap;

    @if $ac-partners-items-have-border-dividers == false and $ac-partners-items-have-padding == true {
        padding: 0 $default-padding / 2;
    }
}


//
//      ITEM
//

.ac_partners_item {
    display: flex;
    position: relative;
    flex-direction: column;
    margin-bottom: $ac-partners-item-margin-bottom;

    @if $ac-partners-items-on-small-two-items-a-row == true {
        width: percentage(1/2);
        max-width: percentage(1/2);
    } @else {
        width: 100%;
        max-width: 100%;
    }

    @if $ac-partners-items-have-padding == true {
        padding: 0 $default-padding;
    } @else {
        padding: 0;
    }

    @if $ac-partners-items-have-border-dividers == true {
        @if $ac-partners-items-have-padding == true {
            padding: 0 $default-padding;
        }
        border-left: $ac-partners-item-border;
        
    } @else {
        @if $ac-partners-items-have-padding == true {
            padding: 0 $default-padding / 2;
        }
    }   

    &.v_smaller-logo {
        .ac_partners_item_logo {
            transform: scale(.8);
        }
    }
    &.v_larger-logo {
        .ac_partners_item_logo {
            transform: scale(1.2);
        }
    }

    @if $ac-partners-items-have-border-dividers == true {

        @include breakpoint(small only) {
            @include last(1) {
                .ac_partners_item_container {
                    border-bottom: none;
                }
            }
        }
        
    }

}
a.ac_partners_item { // links only
    &:hover {
        @include breakpoint(large) {
            .ac_partners_item_logo {
                opacity: $ac-partners-item-logo-hover-opacity;
            }
            .ac_partners_item_description {
                color: $ac-partners-items-description-hover-color;
            }
        }
    } 
}

.ac_partners_item_container {
    height: 100%;
    position: relative;
    background: $ac-partners-item-content-background;
    padding: $ac-partners-item-container-padding;

    @if $ac-partners-items-have-border-dividers == true {
        border-bottom: $ac-partners-item-border;
    }

    @if $ac-partners-item-shadow == true {
        box-shadow: $default-box-shadow;
    }
}


//
//      BORDER RESPONSIVENESS
//


[data-s-type="partners"] {
    &:not(.v_is-slider) {
        @if $ac-partners-items-have-border-dividers == true {
            
            @if $ac-partners-items-on-small-two-items-a-row == false {

                // border-left
                @include border-responsiveness('S1');
                @include border-responsiveness('S1-MP2');
                @include border-responsiveness('S1-MP2-M3');
                @include border-responsiveness('S1-MP2-M3-L4');
                @include border-responsiveness('S1-MP2-L5');

                // border-bottom
                @include bottom-items-no-border('S1-MP2');
                @include bottom-items-no-border('S1-MP2-L3');
                @include bottom-items-no-border('S1-MP2-L4');
                @include bottom-items-no-border('S1-MP2-L5');

            } @else {

                // border-left
                @include border-responsiveness('S1');
                @include border-responsiveness('S2');
                @include border-responsiveness('S2-M3');
                @include border-responsiveness('S2-L4');
                @include border-responsiveness('S2-L5');

                // border-bottom
                @include bottom-items-no-border('S2');
                @include bottom-items-no-border('S2-M3');
                @include bottom-items-no-border('S2-L4');
                @include bottom-items-no-border('S2-L5');

            }

        }
    }
}


//
//      DESCRIPTION
//

.ac_partners_item_description {
    
    @if $ac-partners-items-description-enable == true {
    text-align: center;
    font-size: 95%;
    color: $ac-partners-items-description-color;
    } @else {
        display: none;
    }
}



//
//      LOGO
//

.ac_partners_item_logo_container {
    display: flex;
    width: 100%;
    justify-content: center;
    @if $ac-partners-items-on-small-two-items-a-row == false {
        padding: 0 10vw;
    }
    @include breakpoint(medium-portrait) {
        padding: 0;
    }
}
.ac_partners_item_logo {
    width: 60%;
    height: $ac-partners-item-logo-height;
    max-width: $ac-partners-item-logo-max-width;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    transition: opacity $default-transition-speed;
    opacity: $ac-partners-item-logo-initial-opacity;
}



//
//      SLIDER
//

[data-s-type="partners"] {
    &.v_is-slider {
        .ac_partners_container {
            display: block;
            z-index: inherit; // slick slider will handle z-index

            //slick fuoc fix
            max-height: $ac-partners-items-slider-fouc-height;
            overflow: hidden;
            opacity: 0;
            transition: opacity $default-transition-speed;

            // drop fouc if slick is initialized
            &.slick-initialized  {
                max-height:  $ac-partners-items-slider-fouc-height;
                overflow: visible;
                opacity: 1;
            }
        }

        [data-s-amount-item] {
        // because all items are in one line, margin on the bottom make no sense
            margin-bottom: 0;
        }

        [data-border-bottom] {
            border-bottom-color: transparent;
        }

        // overrule border left behaviour
        @if $ac-partners-items-have-border-dividers == true {
         
            .slick-slide {
                border-left-color: transparent !important;
            }
            .slick-active ~ .slick-active{
                border-left: $ac-partners-item-border !important;
            }
            
        }

        // if amount on small == 1, we can hide the border all together. This also prevents content grow flickers
        @include breakpoint(small only) {
            [data-s-amount-item] {
                border-left-color: transparent;
            }
        }
    }
}