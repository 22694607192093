
/* AC_S_SERVICES - 1
--------------------------------------------------------------------------------------------*/

[data-s-type="products"] {

    // items
    $items-slider-fouc-height: 200px; // what needs to be the flash-of-unwanted-content height before slider has laoded.

    $items-amount: 0; // 0 counts for undefined, if assigned the code will be more optimised
    $items-content-over-image: false;
    $items-have-padding: true;
    $items-have-border-dividers: true;
    $items-have-border-below: true;
    $items-hide-border-below-on-last-row: true;
    $items-border-radius: 0; 
    $items-first-item-full-width: false;
    $items-first-item-full-width-height-multiplier: 1.5;
    $items-on-small-two-items-a-row: true;
    $item-background: none;
    $item-background-hover: none;
    
    // columns
    $items-columns-enabled: false;
    $items-columns-breakpoint: 800px; 
    $items-columns-height: 400px; 
    $items-columns-hide-last-one-below-breakpoint: false;
    $items-columns-icon-image-combination: true;

    // item
    $item-margin-bottom: 1.4em; // 1.4em
    $item-border: $default-border; // defines the appearance, not the display
    $item-shadow-enabled: false;


    // image
    $item-image-container-enabled: true;
    $item-image-enabled: true;
    $item-image-height: 20vw;
    $item-image-initial-fade: 1;
    $item-image-hover-fade: .8;
    $item-image-gradient-before: false; // when item-content-over-image
    $item-image-gradient-color: $default-background-dark;
    $item-image-gradient-before-position: 'bottom'; // top or bottom
    $item-image-background-color: $default-background-light;
    $item-image-background-color-hover: $default-background-light;
    $item-image-border-radius: $default-border-radius; 


    // content
    $item-content-padding: 0; // $default-padding; 
    $item-content-background: none;
    $item-content-background-hover: none;
    $item-content-align: 'lb'; // lt, lc, lb, ct, cc, cb, rt, rc, rb ||  when item-content-over-image
    $item-content-copy-padding: $default-padding 0 ; 
    $item-content-copy-align: 'left'; // left or center

    $item-content-icon-enabled: true;
    $item-content-icon-centered: true;
    $item-content-icon-color: rgba($default-icon-color-light, 1);
    $item-content-icon-hover-color: rgba($default-icon-color-light, .4);
    $item-content-icon-size: 80px;
    $item-content-icon-padding: 10px;
    $item-content-icon-margin: 0 0 20px 0;
    $item-content-icon-background: $default-background-dark;
    $item-content-icon-background-hover: rgba($default-background-dark, .6);
    $item-content-icon-round: true;
    
    $item-content-title-enabled: true; 
    $item-content-title-color: $default-text-color;
    $item-content-title-hover-color: rgba($default-text-color, .6);

    $item-content-copy-above-enabled: true;    
    $item-content-copy-above-color: rgba($default-text-color, .6);
    $item-content-copy-above-hover-color: rgba($default-text-color, .4);

    $item-content-copy-below-enabled: true;    
    $item-content-copy-below-color: rgba($default-text-color, .6);
    $item-content-copy-below-hover-color: rgba($default-text-color, .4);

    // icon
    $item-show-icon: true;
    $item-icon-color: rgba($default-icon-color, .6);
    $item-icon-hover-color: rgba($default-icon-color, .4);
    $item-icon-size: 1.6em;
    $item-icon-position: 'rb'; // lt, lb, rt, rb
    $item-icon-inset-left-right: 0; // $default-padding; 
    $item-icon-inset-top-bottom: $default-padding; 
    

    // list style on small | image left and content right | all background will be reset | content icon is set absolute
    $item-list-style-on-small: false; 
    $item-list-style-image-width: 100px;
    $item-list-style-content-icon-size: 60px; 
    $item-list-style-content-icon-padding: 14px; 
    $item-list-style-content-icon-background: $default-background-light;
    $item-list-style-content-icon-color: $brand-primary; 
    $item-list-style-content-title-color: rgba($default-text-color, 1);
    $item-list-style-content-copy-above-color: rgba($default-text-color, 1);
    $item-list-style-content-copy-below-color: rgba($default-text-color, 1);


    @import '../ac_s__items-globals/__item_globals';
    

    /*--------------------------------------------------------------------------------------------*/
    .ac_item{
        flex-direction: column;
        .ac_item_icon{
            transform: translateX(-3px);
            transition: transform .4s;
            svg {
                fill: rgba($brand-primary, .6)!important;
            }
        }
        &:hover{
            .ac_item_icon{
                transform: translateX(0);
                svg {
                    fill: $brand-primary!important;
                }
            }
            .ac_item_content_title{
                color: $default-text-color!important;
            }
            .ac_item_content_copy-below{
                color: rgba($default-text-color, .6)!important;
            }
            .ac_item_image-container{
                box-shadow: 0 0 0 3px $brand-secondary;
                // &[type="CW3"]{
                //     box-shadow: 0 0 0 3px $brand-green;
                // }
                // &[type="CW4"]{
                //     box-shadow: 0 0 0 3px $brand-aqua;
                // }
                // &[type="CW5"]{
                //     box-shadow: 0 0 0 3px $brand-blue;
                // }
                // &[type="CW6"]{
                //     box-shadow: 0 0 0 3px $brand-purple;
                // }
            }
            .ac_item_image{
                transform: scale(1.05)!important;
            }
        }
    }

    .ac_item_content_copy-below{
        @include breakpoint(small down) {
            font-size: 90%;
        }
    }

    .ac_item_image-container {
        border: $default-border;
    }

    .ac_item_content_price {
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(25%, -25%);
    }   
    .ac_item_content_price_before {
        text-decoration: line-through;
        opacity: .6;
    } 

    .ac_item_type{
        color: #ffffff;
        border-radius: 3px;
        transform: translateX(-10px);
        margin-bottom: 1rem;
        align-items: flex-end;
        display: inline-flex;
        font-size: 120%;
        font-weight: 600;
        padding: 6px 10px;
        &:after{
            font-weight: 500;
            font-size: 80%;
            margin-left: .3rem;
            transform: translateY(-1px);
        }
        @include breakpoint(small down) {
            font-size: 100%;
            padding: 4px 8px;
        }
        &[type="CW3"]{
            background: $brand-green;
            &:after{
                content: ' Bespaar';
            }
        }
        &[type="CW4"]{
            background: $brand-aqua;
            &:after{
                content: ' Comfort';
            }
        }
        &[type="CW5"]{
            background: $brand-blue;
            &:after{
                content: ' Extra';
            }
        }
        &[type="CW6"]{
            background: $brand-purple;
            &:after{
                content: ' Luxe';
            }
        }
    }

    .ac_item_content_copy{
        z-index: 1;
        &[type]{
            margin-top: -40px;
            @include breakpoint(small down) {
                margin-top: -36px;
            }
        }
    }
    .ac_item_content_price_current {
        font-size: 120%;
        background: $brand-secondary;
        font-weight: 600;
        border-radius: 50%;
        width: 75px;
        height: 75px;
        display: flex;
        line-height: 1.2;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: #fff;
        &:after{
            font-weight: 500;
            display: block;
            content: 'p/m';
        }
        @include breakpoint(medium-portrait down) {
            transform: translateX(0px);
        }
        @include breakpoint(small down) {
            font-size: 100%;
            width: 65px;
            height: 65px;
        }
       
    }

    .ac_item_content_title{
        font-weight: 500;
        font-size: 110%;
        margin-bottom: 10px;
        @include breakpoint(small down) {
            font-size: 100%;
        }
    }

    .ac_item_image-container{
        box-shadow: 0 0 0 2px rgba($default-text-color, .1);
        border: 0!important;
        border-radius: 3px;
        transition: box-shadow .4s;
        height: 15vw;
        max-height: 250px;
        overflow: hidden;
        @include breakpoint(medium down) {
            height: 20vw;
        }
        @include breakpoint(medium-portrait down) {
            height: 25vw;
        }
        @include breakpoint(small down) {
            height: 30vw;
        }
        .ac_item_image{
            background-size: contain!important;
        }
    }

    .ac_item_content_label {
        position: absolute;
        top: 12%;
        left: 0;
        z-index: 11;
        background: $brand-primary-darker;
        color: $default-text-color-light;
        padding: $default-padding / 3 $default-padding / 2;
        text-transform: uppercase;
        @include font-headings-bold;
    }

} // end scope