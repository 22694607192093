/* FONTS
--------------------------------------------------------------------------------------------*/

@import url("https://fonts.googleapis.com/css?family=Catamaran:300,400,700|Roboto:300,400,700&display=swap");

$default-font-family-headings: "Catamaran", "Helvetica Neue", Helvetica, Roboto,
  Arial, sans-serif;
$default-font-weight-headings: 700;

$default-font-family-body: "Roboto", "Helvetica Neue", Helvetica, Roboto, Arial,
  sans-serif;
$default-font-family-body-weight: 400;
$default-font-family-body-weight-bold: 700;

@mixin font-headings-light {
  font-family: $default-font-family-headings;
  font-weight: 400;
  font-style: normal;
}

@mixin font-headings-regular {
  font-family: $default-font-family-headings;
  font-weight: $default-font-weight-headings;
  font-style: normal;
}

@mixin font-headings-bold {
  font-family: $default-font-family-headings;
  font-weight: 700;
  font-style: normal;
}

@mixin font-body-light {
  font-family: $default-font-family-body;
  font-weight: 400;
  font-style: normal;
}

@mixin font-body-regular {
  font-family: $default-font-family-body;
  font-weight: $default-font-family-body-weight;
  font-style: normal;
}

@mixin font-body-bold {
  font-family: $default-font-family-body;
  font-weight: $default-font-family-body-weight-bold;
  font-style: normal;
}
