//
//      CONTENT
//

.ac_item_content{
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    padding: $item-content-padding;
    transition: all $default-transition-speed;
    @if $item-content-background != none or $item-content-background != transparent {
        background: $item-content-background;
    }
}
.ac_item_content_copy {
    padding: $item-content-copy-padding;
    width: 100%;
    transition: all $default-transition-speed;
    @if $item-content-copy-align == 'center' {
        text-align: center;
    }

    // * {
    //     transition: all $default-transition-speed;
    // }
}

@if  $item-content-icon-enabled == true {
    .ac_item_content_icon_container {
        @if $item-content-icon-centered == true {
            display: flex;
            justify-content: center;
        }
    }
    .ac_item_content_icon {
        width: $item-content-icon-size;
        height: $item-content-icon-size;
        background: $item-content-icon-background;
        padding: $item-content-icon-padding;
        margin: $item-content-icon-margin;
        
        @if $item-content-icon-round == true {
            border-radius: 100%;
        }
        svg {
            width: $item-content-icon-size - ($item-content-icon-padding * 2);
            height: $item-content-icon-size - ($item-content-icon-padding * 2);
            @if $svg-icon-set-type == 'fill' {
                fill: $item-content-icon-color;
            } @elseif $svg-icon-set-type == 'stroke' {
                stroke: $item-content-icon-color;
                fill: transparent;
            }
        }
    }
} @else {
    .ac_item_content_icon_container { 
        display: none;
    }
}


.ac_item_content_title {
    @if $item-content-title-enabled == true {
        color: $item-content-title-color;
    } @else {
        display: none;
    }
}
.ac_item_content_copy-above {
    @if $item-content-copy-above-enabled == true{
        color: $item-content-copy-above-color;
    } @else {
        display: none;
    }
}
.ac_item_content_copy-below {
    @if $item-content-copy-below-enabled == true{
        color: $item-content-copy-below-color;
    } @else {
        display: none;
    }
}


//
//      ICON // e.g. an arrow
//

.ac_item_icon {
    @if $item-show-icon == true {
        
        position: absolute;
        z-index: 2;
        width: $item-icon-size;
        height: $item-icon-size;
        
        svg {
            width: $item-icon-size;
            height: $item-icon-size;
            fill: $item-icon-color;
            transition: fill $default-transition-speed;
        }
        @if $item-icon-position == 'rt' {
            right: $item-icon-inset-left-right;
            top: $item-icon-inset-top-bottom;
        }
        @if $item-icon-position == 'rb' {
            right: $item-icon-inset-left-right;
            bottom: $item-icon-inset-top-bottom;
        }
        @if $item-icon-position == 'lb' {
            left: $item-icon-inset-left-right;
            bottom: $item-icon-inset-top-bottom;
        }
        @if $item-icon-position == 'lt' {
            left: $item-icon-inset-left-right;
            top: $item-icon-inset-top-bottom;
        }
    } @else {
        display: none;
    }
    
}
